/* eslint-disable no-undef */
import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  connectSearchBox,
  connectHits
} from "react-instantsearch-dom";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import { ResponsiveWidthContainer, SectionTitle } from "../components/Section";
import { Rating } from "../components/Rating";

let searchClient,
  searchEnabled = false;

if (ALGOLIA_APPLICATION_ID && ALGOLIA_API_KEY) {
  searchClient = algoliasearch(
    `${ALGOLIA_APPLICATION_ID}`,
    `${ALGOLIA_API_KEY}`
  );
  searchEnabled = true;
}

const normalizeName = text =>
  // eslint-disable-next-line no-useless-escape
  text.replace(/[^A-Za-z0-9_\.]+/, "-").toLowerCase();

const companyHitStyle = `
  font-size: 2rem;
  > * {
    display: inline-block;
    margin-right: 1rem;
  }
  text-decoration: none;
  div {
    font-weight: bold;
    color: gray;    
  }
`;

// eslint-disable-next-line react/prop-types
const CompanyHit = ({ hit, ..._rest }) => {
  const linkSlug = `reviews/${normalizeName(hit.company_name)}`;
  return (
    <Link to={linkSlug} css={companyHitStyle}>
      <div>{hit.company_name}</div>
      <Rating count={hit.ratings.total} />
    </Link>
  );
};

const searchInputStyle = `
  width: 100%;
  font-size: 2rem;
  line-height: 2.5rem;
  border-radius: .25rem;
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  margin-bottom: 2rem;
`;

// eslint-disable-next-line react/prop-types
const SearchBox = ({ currentRefinement, _isSearchStalled, refine }) => (
  <form noValidate action="" role="search">
    <input
      type="search"
      value={currentRefinement}
      onChange={event => refine(event.currentTarget.value)}
      css={searchInputStyle}
    />
    {/* {isSearchStalled ? "My search is stalled" : ""} */}
  </form>
);

const CustomSearchBox = connectSearchBox(SearchBox);

const StyledHitList = styled.ol`
  list-style-type: none;
  padding-left: 0;
`;

const Hits = ({ hits }) => {
  return (
    <StyledHitList>
      {hits.map(hit => (
        <li
          key={hit.objectID}
          css={`
            border-bottom: 1px solid lightgray;
            padding-bottom: 1rem;
            margin-bottom: 1rem;
          `}
        >
          <CompanyHit hit={hit} />
        </li>
      ))}
    </StyledHitList>
  );
};

Hits.propTypes = {
  hits: PropTypes.array
};

const CustomHits = connectHits(Hits);

export const ReviewSearchPageTemplate = ({
  title,
  content,
  contentComponent
}) => {
  const PageContent = contentComponent || Content;

  return (
    <>
      {searchEnabled && (
        <InstantSearch searchClient={searchClient} indexName="test-ysq">
          <ResponsiveWidthContainer bgColor="#edeff1">
            <>
              <SectionTitle leftAlign>{title}</SectionTitle>
              <CustomSearchBox autoFocus />
            </>
          </ResponsiveWidthContainer>
          <ResponsiveWidthContainer>
            <CustomHits />
          </ResponsiveWidthContainer>
        </InstantSearch>
      )}
      <ResponsiveWidthContainer>
        <PageContent className="content" content={content} />
      </ResponsiveWidthContainer>
    </>
  );
};

ReviewSearchPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func
};

const ReviewSearchPage = ({ data, location }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout location={location}>
      <ReviewSearchPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

ReviewSearchPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object
};

export default ReviewSearchPage;

export const reviewSearchPageQuery = graphql`
  query ReviewSearchPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
