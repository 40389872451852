import React from "react";
import PropTypes from "prop-types";
import { colors } from "../style";

import { FaStar } from "react-icons/fa";

// eslint-disable-next-line no-unused-vars
export const Rating = ({ count = 0, type = "star", outOf = 5, className }) => {
  let RatingIcon;
  switch (type) {
    case "star":
      RatingIcon = FaStar;
      break;
    default:
      RatingIcon = FaStar;
  }
  const floored = Math.floor(count);
  const rounded = (Math.ceil(count) * 2.0) / 2.0;
  const items = [];
  for (let i = 0; i < floored; i++) {
    items.push(<RatingIcon key={i} />);
  }
  if (rounded - floored >= 1) {
    items.push(<RatingIcon key={floored} />);
  }
  return (
    <span
      css={`
        padding-right: 0.5rem;
        color: ${colors.button_main};
      `}
      className={className}
    >
      <span
        css={`
          font-weight: bold;
          padding-right: 0.5rem;
        `}
      >
        {parseFloat(count).toFixed(1)}
      </span>
      <span
        css={`
          svg {
            font-size: inherit;
            margin-bottom: 10px;
          }
        `}
      >
        {items}
      </span>
    </span>
  );
};

Rating.propTypes = {
  count: PropTypes.number.isRequired,
  type: PropTypes.string,
  outOf: PropTypes.number,
  className: PropTypes.string
};
